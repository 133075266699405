export interface LanguageMeta {
    editing: string[];
    reset: number;
    eng?: { [key: string]: string; };
    lit?: { [key: string]: string; };
    lav?: { [key: string]: string; };
    rus?: { [key: string]: string; };
    est?: { [key: string]: string; };
}

export enum EDragTypes {
    Card = 'Card'
}