import { Category } from "./Category";
import { FeaturedList } from "./Featured";
import { LanguageMeta } from "./Shared";
import { EShowItemType, ShowItem } from "./ShowItem";

export enum EPageType {
    DynamicPage = 'DynamicPage'//,
    //StaticPage = 'StaticPage'
}

export enum EPageSectionTargetType {
    FeaturedList = 'FeaturedList',
    //CategoryByUpdate = 'CategoryByUpdate',
    FixedShowItemList = 'FixedShowItemList',
    CustomShowItemList = 'CustomShowItemList',
    CustomMultiCatList = 'CustomMultiCatList',
    SimpleViewCountList = 'SimpleViewCountList'
}

export enum ECustomShowItemListSortBy {
    UpdatedTime = 'publishedAt:desc',
    CreatedTime = 'createdAt:desc'
}

export interface IPageSectionTargetConfig {
    acceptTypes: EShowItemType[];
    audioLangs: string[];
    subtitleLangs: string[];
    sort: 'publishedAt:desc' | 'createdAt:desc'

    //SimpleViewCountList
    viewCountList?: {
        theme: EViewCountListTheme;
        groupBy: EViewCountListGroup;
    }
}

export interface Page {
    id: number;
    type: EPageType;
    title: string;
    description: string;
    slug: string;
    sortOrder: number;
    default: boolean;
    hidden: boolean;
    hiddenUntil: Date | null;
    langMeta: LanguageMeta;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
}

export interface PageSection {
    id: number;
    title: string;
    slug: string;
    sortOrder: number;
    targetType: EPageSectionTargetType;
    targetId: number;
    targetIds: number[];
    targetEntity: Category | FeaturedList | any | null;
    targetEntities: ShowItem[] | Category[] | any[] | null;
    targetConfig: IPageSectionTargetConfig;
    hidden: boolean;
    hiddenUntil: Date | null;
    langMeta: LanguageMeta;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
}

export enum EViewCountListGroup {
    Last24H = 'Last24H'
}

export enum EViewCountListTheme {
    Top10Today = 'Top10Today'
}